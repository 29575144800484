<template>
  <div class="full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">Eventos</h3>
    </div>
    <div class="card-body table-responsive  table-responsive">
      <div class="row d-flex align-items-end">
        <div class="form-group col-md-3">
          <label>Tipo</label>
          <select v-model="pesquisa.tipo" class="form-control">
            <option value="nome_evento">nome Evento</option>
            <option value="produtora_produtor">produtor</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="text">Campo</label>
          <input
            v-model="pesquisa.texto"
            type="text"
            class="form-control"
            id="text"
            placeholder
          />
        </div>

        <div class="form-group col-md-3">
          <label for="text">cidade</label>
          <input
            v-model="pesquisa.cidade"
            type="text"
            class="form-control"
            id="text"
            placeholder
          />
        </div>
      </div>

      <b-table
      responsive
        :fields="[
          { key: 'id', sortable: true  },

          { key: 'nome_evento', sortable: true },
          { key: 'data_evento', sortable: true },
          'nome_local_evento',
          'produtora_produtor',
          { key: 'cidade', sortable: true },
          'created_at',
          { key: 'status', sortable: true },
          'acoes',
        ]"
        :items="lista_eventos_ativos"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        id="filiais-table"
        class="table table-head-custom  table-vertical-center table-head-bg"
        style="font-size: 12px;min-height: 200px;"
        show-empty
        empty-text="Nenhum registro encontrado!"
      >
        <template #cell(created_at)="{ item }"
          ><span>{{ item.created_at | formatEnToBr }}</span></template
        >
        <template #head(data_evento)> Inicio </template>
        <template #head(nome_local_evento)> Local </template>
        <template #head(produtora_produtor)> Produtora </template>

        <template #head(created_at)> Criado </template>
        <template #head(cidade)> Cidade - Uf </template>

        <template #cell(cidade)="{ item }">
          <span>{{ item.cidade + " - " + item.uf }}</span>
        </template>
        <template #cell(data_evento)="{ item }">
          <span>{{ item.data_evento | formatEnToBr }}</span>
        </template>

        <template #cell(status)="{ item }">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success': item.status === 1,
              'label-light-danger': item.status === 0,
              'label-light-warning': item.status === 2,
              'label-light-info': item.status === 4,
            }"
          >
            {{
              item.status === 1
                ? "Ativo"
                : item.status === 4
                ? " Concluido"
                : "Desativado"
            }}
          </span></template
        >
        <template #cell(acoes)="{ item }">
          <div class="text-right w-100">
            <div class="dropdown show">
              <a
                class="btn btn-sm btn-hover-light-primary"
                data-toggle="dropdown"
              >
                <i class="ki ki-bold-more-ver p-0"></i>
              </a>

              <div class="dropdown-menu">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="lista_permissoes_filial[btn.permisao]"
                      outlined
                      class="mr-1 mt-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      
      <b-pagination
        v-model="currentPage"
        :total-rows="lista_eventos_ativos.length"
        :per-page="perPage"
        aria-controls="filiais-table"
      >
      </b-pagination>
      <b-table
      v-if="lista_eventos[0]"
        responsive
        :fields="[
          { key: 'id', sortable: true  },

          { key: 'nome_evento', sortable: true },
          { key: 'data_evento', sortable: true },
          'nome_local_evento',
          'produtora_produtor',
          { key: 'cidade', sortable: true },
          'created_at',
          { key: 'status', sortable: true },
          'acoes',
        ]"
        :items="lista_eventos"
        :per-page="perPage"
        :current-page="currentPage"
        hover
        id="filiais-table"
        class="table table-head-custom  table-vertical-center table-head-bg pt-5"
        style="font-size: 12px"
        show-empty
        empty-text="Nenhum registro encontrado!"
      >
        <template #cell(created_at)="{ item }"
          ><span>{{ item.created_at | formatEnToBr }}</span></template
        >
        <template #head(data_evento)> Inicio </template>
        <template #head(nome_local_evento)> Local </template>
        <template #head(produtora_produtor)> Produtora </template>

        <template #head(created_at)> Criado </template>
        <template #head(cidade)> Cidade - Uf </template>

        <template #cell(cidade)="{ item }">
          <span>{{ item.cidade + " - " + item.uf }}</span>
        </template>
        <template #cell(data_evento)="{ item }">
          <span>{{ item.data_evento | formatEnToBr }}</span>
        </template>

        <template #cell(status)="{ item }">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success': item.status === 1,
              'label-light-danger': item.status === 0,
              'label-light-warning': item.status === 2,
              'label-light-info': item.status === 4,
            }"
          >
            {{
              item.status === 1
                ? "Ativo"
                : item.status === 4
                ? " Concluido"
                : "Desativado"
            }}
          </span></template
        >
        <template #cell(acoes)="{ item }">
          <div class="text-right w-100">
            <div class="dropdown show">
              <a
                class="btn btn-sm btn-hover-light-primary"
                data-toggle="dropdown"
              >
                <i class="ki ki-bold-more-ver p-0"></i>
              </a>

              <div class="dropdown-menu">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="lista_permissoes_filial[btn.permisao]"
                      outlined
                      class="mr-1 mt-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      
      <b-pagination
      v-if="lista_eventos[0]"

        v-model="currentPage"
        :total-rows="lista_eventos.length"
        :per-page="perPage"
        aria-controls="filiais-table"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";
import EventoService from "@/core/services/evento.service";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pesquisa: {
        tipo: "nome",
        texto: "",
        cidade: "",
      },
      lista_btn: [
        //    {
        //   permisao:"c_Evento",
        //   button: "entrar",
        //   title: "Gerenciar Evento",
        //   color: "warning",
        //   icon: "fa fa-arrow-right text-warning",
        // },
        {
          permisao: "u_Evento",
          button: "editar",
          title: "Editar registro",
          color: "info",
          icon: "far fa-edit text-primary",
          ixbir: true,
        },
        {
          permisao: "lock_Evento",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-trash text-danger",
          ixbir: true,
        },
        {
          permisao: "lock_Evento",
          button: "ingressos",
          title: "ingressos",
          color: "info",
          icon: "fas fa-ticket-alt text-info",
          ixbir: true,
        },
        {
          permisao: "ra_Evento",
          button: "evento_id",
          title: "Evento GLobal",
          color: "success",
          icon: "fas fa-play text-success",
          ixbir: true,
        },
        {
          permisao: "ra_Evento",
          button: "taxa_evento",
          title: "Taxa Eventos",
          color: "info",
          icon: "fas fa-dollar-sign text-info",
          ixbir: true,
        },

     
        {
          permisao: "ra_Evento",
          button: "link",
          title: "link",
          color: "dark",
          icon: "fa fa-link text-secondary",
          ixbir: false,
        },
      ],
    };
  },
  mixins: [dateFormat],

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Evento" }]);
  },
  computed: {
    lista_eventos() {
      let val = this.$store.state.evento.lista_eventos.filter((item) => {
        // if (!this.pesquisa.texto) return true;
        return (
          String(item['status'])
            .toLowerCase()
            .indexOf('1'.toLowerCase()) === -1
        );
      });

      let val2 = val.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });

      return val2.filter((item) => {
        return (
          String(item["cidade"])
            .toLowerCase()
            .indexOf(this.pesquisa.cidade.toLowerCase()) !== -1
        );
      });
    },

    lista_eventos_ativos(){
      let val = this.$store.state.evento.lista_eventos.filter((item) => {
        // if (!this.pesquisa.texto) return true;
        return (
          String(item['status'])
            .toLowerCase()
            .indexOf('1'.toLowerCase()) !== -1
        );
      });

      let val2 = val.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });

      return val2.filter((item) => {
        return (
          String(item["cidade"])
            .toLowerCase()
            .indexOf(this.pesquisa.cidade.toLowerCase()) !== -1
        );
      });
    },

    lista_eventos_ativos_desativos(){
      return this.$store.state.evento.lista_eventos;
    //   let val = this.$store.state.evento.lista_eventos.filter((item) => {
    //     // if (!this.pesquisa.texto) return true;
    //     return (
    //       String(item['status'])
    //         .toLowerCase()
    //         .indexOf('1'.toLowerCase()) !== -1
    //     );
    //   });
    // let val2 = this.$store.state.evento.lista_eventos.filter((item) => {
    //     // if (!this.pesquisa.texto) return true;
    //     return (
    //       String(item['status'])
    //         .toLowerCase()
    //         .indexOf('2'.toLowerCase()) !== -1
    //     );
    //   });

    //   let val3 = this.$store.state.evento.lista_eventos.filter((item) => {
    //     // if (!this.pesquisa.texto) return true;
    //     return (
    //       String(item['status'])
    //         .toLowerCase()
    //         .indexOf('6'.toLowerCase()) !== -1
    //     );
    //   });
    //   return  val.concat(val2).concat(val3)

    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_eventos();
  },
  methods: {
    acoes(item, tipo) {
      EventoService.setEventoId(item.id);
      if (tipo == "evento_id") {
        // console.log(item)
        EventoService.setEventoId(item.id);

        this.alertMessage("success", "Selecionado Evento Global");
      }
      if (tipo == "editar") this.atualizar(item);
      if (tipo == "exclusao") this.confirm(item);
      if (tipo == "ingressos") this.ingressos(item);
      if (tipo == "taxa_evento") this.taxa_evento();
      //   /   title: "",
      // //   route: "/taxaEventos",
      // //   permissao: "ra_pdv",
      


    
      if (tipo == "link") {
        if (item.status == 6) {
          this.$copyText(
            "https://boraticketstore.com.br/#/preview/" +
              item.nome_evento.replace(/ /g, "-") +
              "/" +
              item.id
          );
        }
        if (item.status == 1) {
          this.$copyText(
            "https://boraticketstore.com.br/#/" +
              item.nome_evento.replace(/ /g, "-") +
              "/" +
              item.id
          );
        }
        if (item.status == 2) {
          this.$copyText(
            "https://boraticketstore.com.br/#/atualizarEvento" + item.id
          );
        }
      }
    },
    ingressos(value) {
      this.$router.push({
        name: "ingressosE",
        params: { eventos_id: value.id },
      });
    },
    taxa_evento() {
      this.$router.push({
        name: "taxaEventos",
      });
    },
    async listar_eventos() {
      await this.$store.dispatch("evento/listar_eventos");

      this.$store.dispatch("configEmpresa/atualizar", "");
      EventoService.saveEventos(this.lista_eventos_ativos_desativos);

    },
    atualizar(value) {
      this.$router.push({ name: "createEvento" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },

    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este Evento no sistema?`,
        button: {
          no: "Nao",
          yes: "sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("evento/delete_evento", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>