var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body table-responsive table-responsive"},[_c('div',{staticClass:"row d-flex align-items-end"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.pesquisa.tipo),expression:"pesquisa.tipo"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.pesquisa, "tipo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"nome_evento"}},[_vm._v("nome Evento")]),_c('option',{attrs:{"value":"produtora_produtor"}},[_vm._v("produtor")])])]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"text"}},[_vm._v("Campo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pesquisa.texto),expression:"pesquisa.texto"}],staticClass:"form-control",attrs:{"type":"text","id":"text","placeholder":""},domProps:{"value":(_vm.pesquisa.texto)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pesquisa, "texto", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"text"}},[_vm._v("cidade")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pesquisa.cidade),expression:"pesquisa.cidade"}],staticClass:"form-control",attrs:{"type":"text","id":"text","placeholder":""},domProps:{"value":(_vm.pesquisa.cidade)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pesquisa, "cidade", $event.target.value)}}})])]),_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg",staticStyle:{"font-size":"12px","min-height":"200px"},attrs:{"responsive":"","fields":[
        { key: 'id', sortable: true  },

        { key: 'nome_evento', sortable: true },
        { key: 'data_evento', sortable: true },
        'nome_local_evento',
        'produtora_produtor',
        { key: 'cidade', sortable: true },
        'created_at',
        { key: 'status', sortable: true },
        'acoes',
      ],"items":_vm.lista_eventos_ativos,"per-page":_vm.perPage,"current-page":_vm.currentPage,"hover":"","id":"filiais-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatEnToBr")(item.created_at)))])]}},{key:"head(data_evento)",fn:function(){return [_vm._v(" Inicio ")]},proxy:true},{key:"head(nome_local_evento)",fn:function(){return [_vm._v(" Local ")]},proxy:true},{key:"head(produtora_produtor)",fn:function(){return [_vm._v(" Produtora ")]},proxy:true},{key:"head(created_at)",fn:function(){return [_vm._v(" Criado ")]},proxy:true},{key:"head(cidade)",fn:function(){return [_vm._v(" Cidade - Uf ")]},proxy:true},{key:"cell(cidade)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.cidade + " - " + item.uf))])]}},{key:"cell(data_evento)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatEnToBr")(item.data_evento)))])]}},{key:"cell(status)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
            'label-light-success': item.status === 1,
            'label-light-danger': item.status === 0,
            'label-light-warning': item.status === 2,
            'label-light-info': item.status === 4,
          }},[_vm._v(" "+_vm._s(item.status === 1 ? "Ativo" : item.status === 4 ? " Concluido" : "Desativado")+" ")])]}},{key:"cell(acoes)",fn:function({ item }){return [_c('div',{staticClass:"text-right w-100"},[_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"btn btn-sm btn-hover-light-primary",attrs:{"data-toggle":"dropdown"}},[_c('i',{staticClass:"ki ki-bold-more-ver p-0"})]),_c('div',{staticClass:"dropdown-menu"},_vm._l((_vm.lista_btn),function(btn,index){return _c('v-tooltip',{key:index,attrs:{"top":"","color":btn.color},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial[btn.permisao]),expression:"lista_permissoes_filial[btn.permisao]"}],staticClass:"mr-1 mt-1",attrs:{"outlined":"","color":btn.color,"small":""},on:{"click":function($event){return _vm.acoes(item, btn.button)}}},'v-btn',attrs,false),on),[_c('i',{class:btn.icon})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(btn.title))])])}),1)])])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_eventos_ativos.length,"per-page":_vm.perPage,"aria-controls":"filiais-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.lista_eventos[0])?_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg pt-5",staticStyle:{"font-size":"12px"},attrs:{"responsive":"","fields":[
        { key: 'id', sortable: true  },

        { key: 'nome_evento', sortable: true },
        { key: 'data_evento', sortable: true },
        'nome_local_evento',
        'produtora_produtor',
        { key: 'cidade', sortable: true },
        'created_at',
        { key: 'status', sortable: true },
        'acoes',
      ],"items":_vm.lista_eventos,"per-page":_vm.perPage,"current-page":_vm.currentPage,"hover":"","id":"filiais-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatEnToBr")(item.created_at)))])]}},{key:"head(data_evento)",fn:function(){return [_vm._v(" Inicio ")]},proxy:true},{key:"head(nome_local_evento)",fn:function(){return [_vm._v(" Local ")]},proxy:true},{key:"head(produtora_produtor)",fn:function(){return [_vm._v(" Produtora ")]},proxy:true},{key:"head(created_at)",fn:function(){return [_vm._v(" Criado ")]},proxy:true},{key:"head(cidade)",fn:function(){return [_vm._v(" Cidade - Uf ")]},proxy:true},{key:"cell(cidade)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.cidade + " - " + item.uf))])]}},{key:"cell(data_evento)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatEnToBr")(item.data_evento)))])]}},{key:"cell(status)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
            'label-light-success': item.status === 1,
            'label-light-danger': item.status === 0,
            'label-light-warning': item.status === 2,
            'label-light-info': item.status === 4,
          }},[_vm._v(" "+_vm._s(item.status === 1 ? "Ativo" : item.status === 4 ? " Concluido" : "Desativado")+" ")])]}},{key:"cell(acoes)",fn:function({ item }){return [_c('div',{staticClass:"text-right w-100"},[_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"btn btn-sm btn-hover-light-primary",attrs:{"data-toggle":"dropdown"}},[_c('i',{staticClass:"ki ki-bold-more-ver p-0"})]),_c('div',{staticClass:"dropdown-menu"},_vm._l((_vm.lista_btn),function(btn,index){return _c('v-tooltip',{key:index,attrs:{"top":"","color":btn.color},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial[btn.permisao]),expression:"lista_permissoes_filial[btn.permisao]"}],staticClass:"mr-1 mt-1",attrs:{"outlined":"","color":btn.color,"small":""},on:{"click":function($event){return _vm.acoes(item, btn.button)}}},'v-btn',attrs,false),on),[_c('i',{class:btn.icon})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(btn.title))])])}),1)])])]}}],null,false,655690633)}):_vm._e(),(_vm.lista_eventos[0])?_c('b-pagination',{attrs:{"total-rows":_vm.lista_eventos.length,"per-page":_vm.perPage,"aria-controls":"filiais-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Eventos")])])
}]

export { render, staticRenderFns }